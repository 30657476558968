function About() {
  return (
    <section className="py-12">
      <div className="container">
        <div className="max-w-2xl mx-auto">
          <h4 className="mb-8 text-center">
            Decentralize Trust, Reclaim Your Identity.
          </h4>
          <p className="mb-12 lead text-muted">
            At <span className="fw-bold">Xine Technology Solutions Pvt Ltd</span>, we believe in a world where
            individuals have complete control over their digital identities.
            Founded with a mission to empower people through secure,
            decentralized technology, we are pioneering solutions that protect
            personal data while simplifying the way people interact online.</p><p className="mb-12 lead text-muted"> In
            an era of increasing digital surveillance, data breaches, and misuse
            of personal information, we saw the need for a better approach—one
            that gives individuals full ownership and control of their identity.
            Our decentralized identity platform is built on the principles of
            privacy, security, and transparency, using cutting-edge blockchain
            technology to eliminate the need for intermediaries.
          </p>
          <div className="mb-12" style={{height: '440px'}}>
            <img
              className="img-fluid h-100 w-100 rounded"
              style={{objectFit: 'contain'}}
              src="xine-assets/images/features/aboutus.jpg"
              alt=""
            />
          </div>
          <p className="mb-8 lead text-muted">
          <span className="fw-bold">Our vision</span> To put the power of identity back into the
            hands of individuals. Whether it's securing access to your favorite
            online services, protecting sensitive healthcare data, or verifying
            your identity for financial transactions, we enable you to manage
            and share your personal information on your own terms.</p><p className="mb-8 lead text-muted"> We are a team
            of innovators, technologists, and privacy advocates, all driven by
            the belief that decentralization is the future of identity. With a
            focus on user-centric solutions, we’re committed to making digital
            interactions safer, seamless, and more secure for everyone.
          </p>
          <p className="mb-8 lead text-muted">
          <span className="fw-bold">Our Mission</span> To revolutionize digital identity by providing secure, decentralized
            solutions that allow individuals to own, control, and protect their
            personal data.</p> 
            <p className="mb-8 lead text-muted">
              <span className="fw-bold">Our Values</span>
              <ul className="list-group list-group-flush"> 
               <li className="list-group-item"><span className="fw-bold">Privacy by Design:</span> We build with privacy as the foundation, ensuring that your data is always under your control.</li> 
               <li className="list-group-item"><span className="fw-bold">Security First:</span> Leveraging blockchain and encryption technology to ensure that your identity is safe from tampering and breaches.</li> 
               <li className="list-group-item"><span className="fw-bold">Empowerment:</span> Putting the control back in your hands, so you decide how and when your personal data is shared.</li> 
               <li className="list-group-item"><span className="fw-bold">Innovation:</span> Continuously pushing boundaries to provide cutting-edge solutions that keep up with the evolving digital landscape.</li>
              </ul>
            </p>
        </div>
      </div>
    </section>
  );
}
export default About;
