import About from "./aboutus";
import Header from "../Header";
import Footer from "../Footer";


function Aboutus() {
  return (
    <div>
      <Header />
      <About />
      <Footer />
    </div>
  );
}

export default Aboutus;
