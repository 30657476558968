import Footer from "../Footer";
import Header from "../Header";
import Content from "./content";

function Home() {
    return (
       <div>
         <Header />
         <Content />
         <Footer />
       </div>
    )
}

export default Home;