import { Link } from "react-router-dom";

function Content() {
    return (
        <div className="">
            <div>
                <section className="position-relative pb-16 overflow-hidden">
                    <div className="px-4 px-sm-10 mb-16">
                        <div className="position-relative pb-72 pb-sm-96 pt-24 pb-lg-24 rounded bg-primary-light bg-opacity-50 overflow-hidden">
                            <img className="position-absolute bottom-0 start-0" src="xine-assets/images/headers/gradient3.svg" alt="" />
                            <img className="position-absolute bottom-0 end-0 mw-md-xl mw-lg-2xl mw-xl-none img-fluid" src="xine-assets/images/headers/profiles.png" alt="" />
                            <div className="container position-relative">
                                <div className="mw-md mw-md-2xl mw-lg-3xl mx-auto mx-lg-0">
                                    <h1 className="h2 mb-8">Empowering You to Own Your Digital Identity</h1>
                                    <p className="mw-md mb-10 fs-7"> Say goodbye to centralized authorities, and hello to true ownership and security of your online presence</p>
                                    <Link className="btn mb-5 btn-primary" to="#">Talk to Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <h6 className="text-center mb-10 fw-medium text-secondary-dark">In Association With</h6>
                        <div className="mw-6xl mx-auto">
                            <div className="row mb-n6 align-items-center justify-content-between">
                                <div className="col-6 col-sm-4 col-lg-2 mb-6">
                                    <img className="img-fluid d-block mx-auto" src="xine-assets/images/features/iata.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-20 py-md-32 bg-info-light">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-last order-lg-first">
                                <img className="d-block mw-md mw-lg-none mx-auto w-100" src="xine-assets/images/features/qrcode.png" alt="" />
                            </div>
                            <div className="col-12 col-lg-6">
                                <div className="mw-md mx-auto pb-20">
                                    <div className="d-flex pb-8 border-bottom">
                                        <span>
                                            <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M12 3.07987C12.8551 2.11105 14.1062 1.5 15.5 1.5C18.0773 1.5 20.1667 3.58934 20.1667 6.16667C20.1667 8.744 18.0773 10.8333 15.5 10.8333C14.1062 10.8333 12.8551 10.2223 12 9.25346M15.5 22.5H1.5V21.3333C1.5 17.4673 4.63401 14.3333 8.5 14.3333C12.366 14.3333 15.5 17.4673 15.5 21.3333V22.5ZM15.5 22.5H22.5V21.3333C22.5 17.4673 19.366 14.3333 15.5 14.3333C14.225 14.3333 13.0296 14.6742 12 15.2698M13.1667 6.16667C13.1667 8.744 11.0773 10.8333 8.5 10.8333C5.92267 10.8333 3.83333 8.744 3.83333 6.16667C3.83333 3.58934 5.92267 1.5 8.5 1.5C11.0773 1.5 13.1667 3.58934 13.1667 6.16667Z" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </span>
                                        <div className="ps-4">
                                            <h5 className="mb-3">Privacy</h5>
                                            <p className="text-secondary">Your data is shared only with your consent.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex py-8 border-bottom">
                                        <span>
                                            <svg width="28" height="28" viewbox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.83333 14.0001H22.1667M5.83333 14.0001C4.54467 14.0001 3.5 12.9554 3.5 11.6667V7.00008C3.5 5.71142 4.54467 4.66675 5.83333 4.66675H22.1667C23.4553 4.66675 24.5 5.71142 24.5 7.00008V11.6667C24.5 12.9554 23.4553 14.0001 22.1667 14.0001M5.83333 14.0001C4.54467 14.0001 3.5 15.0448 3.5 16.3334V21.0001C3.5 22.2887 4.54467 23.3334 5.83333 23.3334H22.1667C23.4553 23.3334 24.5 22.2887 24.5 21.0001V16.3334C24.5 15.0448 23.4553 14.0001 22.1667 14.0001M19.8333 9.33342H19.845M19.8333 18.6667H19.845" stroke="#4F46E5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </span>
                                        <div className="ps-4">
                                            <h5 className="mb-3">Security</h5>
                                            <p className="text-secondary">Blockchain encryption protects your identity from tampering and breaches.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex pt-8 border-bottom">
                                        <span>
                                            <svg width="28" height="28" viewbox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.5 11.6667H24.5M8.16667 17.5H9.33333M14 17.5H15.1667M7 22.1667H21C22.933 22.1667 24.5 20.5997 24.5 18.6667V9.33337C24.5 7.40038 22.933 5.83337 21 5.83337H7C5.067 5.83337 3.5 7.40038 3.5 9.33337V18.6667C3.5 20.5997 5.067 22.1667 7 22.1667Z" stroke="#6366F1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </span>
                                        <div className="ps-4">
                                            <h5 className="mb-3">Ownership</h5>
                                            <p className="text-secondary">You are the sole custodian of your personal information.</p>
                                        </div>
                                    </div>
                                    <div className="d-flex pt-8">
                                        <span>
                                            <svg width="28" height="28" viewbox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.5 11.6667H24.5M8.16667 17.5H9.33333M14 17.5H15.1667M7 22.1667H21C22.933 22.1667 24.5 20.5997 24.5 18.6667V9.33337C24.5 7.40038 22.933 5.83337 21 5.83337H7C5.067 5.83337 3.5 7.40038 3.5 9.33337V18.6667C3.5 20.5997 5.067 22.1667 7 22.1667Z" stroke="#6366F1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                                            </svg>
                                        </span>
                                        <div className="ps-4">
                                            <h5 className="mb-3">Interoperability</h5>
                                            <p className="text-secondary">Use your identity across platforms without losing control of your data.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-12 py-sm-24 bg-secondary position-relative">
                    <img className="position-absolute bottom-0 end-0 h-100 w-100" src="xine-assets/images/cta/bg-line-gradient.png" alt="" />
                        <div className="container position-relative">
                            <div className="mw-md mx-auto mw-lg-none">
                                <div className="row align-items-end">
                                    <div className="col-12 col-lg-6 col-xl-8 mb-16 mb-lg-0">
                                        <div className="mw-lg">
                                            <h1 className="h3 text-white mb-6">Ready to get started?</h1>
                                            <div className="mw-md opacity-75">
                                                <p className="text-white mb-0">Join the decentralized identity movement and take control of your personal data. Our platform makes it easy to create, manage, and protect your decentralized identity, giving you peace of mind in the digital world.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 col-xl-4">
                                        <div className="d-flex w-100 ps-xl-16 flex-column ms-lg-auto mw-lg-xs">
                                            <Link className="btn mb-4 btn-light fw-medium" to="#">Start 30 days free trial</Link>
                                            <Link className="btn btn-outline-light" to="#">
                                                <svg width="19" height="18" viewbox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2.75 3.75C2.75 2.92157 3.42157 2.25 4.25 2.25H6.70943C7.03225 2.25 7.31886 2.45657 7.42094 2.76283L8.5443 6.13291C8.66233 6.48699 8.50203 6.87398 8.1682 7.0409L6.47525 7.88737C7.30194 9.72091 8.77909 11.1981 10.6126 12.0247L11.4591 10.3318C11.626 9.99796 12.013 9.83767 12.3671 9.9557L15.7372 11.0791C16.0434 11.1811 16.25 11.4677 16.25 11.7906V14.25C16.25 15.0784 15.5784 15.75 14.75 15.75H14C7.7868 15.75 2.75 10.7132 2.75 4.5V3.75Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                                                </svg>
                                                <span className="ms-1 fw-medium">Book a Call</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>
            </div>
        </div>
    )
}

export default Content;