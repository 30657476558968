import { Link } from "react-router-dom";

function Header() {
    return (
        <section>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">
                    <Link className="navbar-brand me-6" to="/">
                        <img className="img-fluid" src="xine-assets/logos/logo.gif" alt="" />
                    </Link>
                    <div className="d-none d-lg-block"><Link className="btn fw-medium me-4" to="#">Sign In</Link><Link className="btn btn-primary" to="#">Try 30 Days Free Trial</Link></div>
                    <div className="d-lg-none">
                        <button className="btn navbar-burger p-0">
                            <svg className="text-primary" width="51" height="51" viewbox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="56" height="56" rx="28" fill="currentColor"></rect>
                                <path d="M37 32H19M37 24H19" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </nav>
            <div className="d-none navbar-menu position-fixed top-0 start-0 bottom-0 w-75 mw-xs" style={{zIndex: 9999}}>
                <div className="navbar-close navbar-backdrop position-fixed top-0 start-0 end-0 bottom-0 bg-dark" style={{opacity: '75%'}}></div>
                <nav className="position-relative h-100 w-100 d-flex flex-column justify-content-between py-8 px-8 bg-white overflow-auto">
                    <div className="d-flex align-items-center">
                        <Link className="me-auto h4 mb-0 text-decoration-none" to="#">
                            <img className="img-fluid" src="xine-assets/logos/logo.gif" alt="" />
                        </Link>
                        <Link className="navbar-close" to="#">
                            <svg width="24" height="24" viewbox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6 18L18 6M6 6L18 18" stroke="#111827" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                            </svg>
                        </Link>
                    </div>
                    <div><Link className="btn w-100 fw-medium" to="#">Login</Link><Link className="btn w-100 btn-primary" to="#">Sign in</Link></div>
                </nav>
            </div>
        </section>
    )
}

export default Header;