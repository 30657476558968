import { Routes, Route } from "react-router-dom";
import Home from "../Home";
import Aboutus from "../About";

function Router() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aboutus" element={<Aboutus />} />
        </Routes>
    )
}

export default Router;